import { useEffect, useRef } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

import { captureException } from "@sentry/react";

import axios from "@/lib/axios";

const useWatchPendingContent = () => {
  const { assistant, removePendingBase, updateStatusAlert } = useAssistantContext();
  const timeoutRef = useRef();

  const analysePendingContent = async () => {
    const pendingBase = assistant.pending_knowledge_base;
    const isMultiUrlProcess = pendingBase.hasOwnProperty("status_alert");
    const controllerPath = isMultiUrlProcess ? "multi_url_processes" : "knowledge_bases";

    try {
      const response = await axios.get(`/ai/${controllerPath}/${pendingBase.id}/?ts=${new Date().getTime()}`);
      const knowledgeBase = response.data.knowledge_base;

      if (knowledgeBase?.status_alert) {
        updateStatusAlert(knowledgeBase.status_alert);
      }
      if (knowledgeBase.status === "pending") return;

      clearInterval(timeoutRef.current);
      removePendingBase();

      if (knowledgeBase.status === "done") {
        toastr.success(I18n.t("views.leadster_ai.page.add_content.alerts.success"));

        return setTimeout(() => window.location.reload(), 2000);
      }

      toastr.error(I18n.t("views.leadster_ai.page.add_content.alerts.error"));
    } catch (error) {
      captureException(error);
      clearInterval(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (!_.get(assistant, "pending_knowledge_base")) {
      return;
    }

    timeoutRef.current = setInterval(async () => {
      await analysePendingContent();
    }, 10000);

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [assistant]);
};

export default useWatchPendingContent;
